<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="修改案例" style="padding: 20px 10px;"/>
		<CasesForm :casesForm="form" @eventForm="handleGetForm"  class="purForm"/>
	</div>
</template>

<script>
	import CasesForm from '@/components/Cases/form'
	export default {
		data() {
			return {
				form: {}
			}
		},
		created() {
			this.$axios.post('/getgcal', {
				id: this.$route.query.id
			}).then(data => {
				this.form = data
			})
		},
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addgcal', form).then(data => {
					this.$message.success('修改成功')
					this.$router.push('/cases')
				})
			}
		},
		components: {
			CasesForm
		}
	}
</script>
<style scoped>
	.purForm{
		width: 1510px;
		margin: 10px auto;
	}
</style>
